/* ion-toolbar, ion-header{ */
    /* --background: white; */
    /* background-color: var(--background); */
/* } */

ion-header{
    height: 5rem;
    display: flex;
    align-items: center;
}
.loading-wrapper{
  border-radius: 16px !important
}
