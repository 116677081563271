.swiper-slide {
    display: block;
    /* height: 250px; */
    /* width: 100px !important; */
    /* background-color: white !important; */
}

.survey-modal ion-card {
    height: 70vh;
}

.survey-modal {
    --background: white;
    --width: 100%;
    --height: 100%;
    /* display: none; */
}

/* .survey-slide .progress-buffer-bar{
    background: var(--ion-color-light);
} */
.survey-slide ion-item{
    --color: var(--ion-color-medium);
    border-radius: 8px;
    border: 0.1em solid var(--ion-color-medium);
}
.survey-slide .item-radio-checked, .survey-slide ion-item:hover{
    --color: var(--ion-color-dark);
    --background: var(--ion-color-light);
    border: 0.1em solid var(--ion-color-primary);
}
