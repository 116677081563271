

.grid {
    display: flex;
    flex-wrap: wrap;
    width: 400px;
    height: 300px;
}

.emotionGrid {
    display: grid;
    width: 100%;
    gap: 0.5em;
    grid-template-columns: repeat( 2, 40vmin);
    grid-template-rows: min(40vw, 30vh) min(40vw, 30vh) min(40vw, 30vh);
    justify-content: center;
    margin-bottom: 20%;
}


.memory__card {
    perspective: 1000px;
}

.memory__card svg {
    --side: 30px;
    width: var(--side);
    height: var(--side);
}

.memory__card .card__inner {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    display: grid;
    grid-template: 1fr / 1fr;
    transition: transform 0.3s;
    place-items: center;
    background-color: white;
}
.invisible{
    visibility: hidden;
}
.card__content,
.card__title {
    grid-area: 1 / 1;
    backface-visibility: hidden;
    /* position: absolute; */

    top: 0;
    left: 0;


}

.card__content {
    transform: rotateY(180deg);
}

.memory__card.flipped .card__inner {
    transform: rotateY(180deg);
}
.memory__card.flipped .card__title {
    visibility: hidden;
}
