

:root{
    --font-extrabold: 800;
    --font-bold: 700;
    --font-semibold: 700;
    --font-regular: 400;
}

h1, h2, h3{
    font-weight: var(--font-extrabold)!important;
}
h4, h5{
    font-weight: var(--font-bold)!important;
}

.button-small, .button-medium, .button-large, .paragraph-small{
    font-weight: var(--font-bold);
    text-transform: none;
}

.paragraph-medium, .paragraph-large{
    font-weight: var(--font-semibold)!important;

}
.button-small{
    font-size: 0.75rem;
    line-height: 150%;
}
.button-medium{
    font-size: 0.875rem;
    line-height: 150%;
}
.button-large{
    font-size: 1rem ;
    line-height: 162%;
}

.flex{
    display: flex;
}

.paragraph-small{
    font-size: 0.75rem;
    line-height: 150%;
}
.paragraph-medium{
    font-size: 0.875rem;
    line-height: 150%;
}
.paragraph-large{
    font-size: 1rem;
    line-height: 162%;
}