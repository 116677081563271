/* nunito-regular - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/nunito-v16-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/nunito-v16-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* nunito-600 - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../fonts/nunito-v16-latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/nunito-v16-latin-600.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* nunito-700 - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/nunito-v16-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/nunito-v16-latin-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* nunito-800 - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  src: local(""), url("../fonts/nunito-v16-latin-800.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/nunito-v16-latin-800.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

:root {
  --ion-color-primary: #366d9c;
  --ion-color-primary-rgb: 54, 109, 156;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #306089;
  --ion-color-primary-tint: #4a7ca6;

  --ion-color-secondary: #f17a48;
  --ion-color-secondary-rgb: 241, 122, 72;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #d46b3f;
  --ion-color-secondary-tint: #f2875a;

  --ion-color-tertiary: #2fa377;
  --ion-color-tertiary-rgb: 47, 163, 119;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #298f69;
  --ion-color-tertiary-tint: #44ac85;

  --ion-color-success: #2fa377;
  --ion-color-success-rgb: 47, 163, 119;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #298f69;
  --ion-color-success-tint: #44ac85;

  --ion-color-warning: #efa948;
  --ion-color-warning-rgb: 239, 169, 72;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #d2953f;
  --ion-color-warning-tint: #f1b25a;

  --ion-color-danger: #ef4949;
  --ion-color-danger-rgb: 239, 73, 73;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d24040;
  --ion-color-danger-tint: #f15b5b;

  --ion-color-dark: #3c3a36;
  --ion-color-dark-rgb: 60, 58, 54;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #353330;
  --ion-color-dark-tint: #504e4a;

  --ion-color-medium: #78746d;
  --ion-color-medium-rgb: 120, 116, 109;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #6a6660;
  --ion-color-medium-tint: #86827c;

  --ion-color-light: #f8f2ee;
  --ion-color-light-rgb: 248, 242, 238;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #dad5d1;
  --ion-color-light-tint: #f9f3f0;

  --ion-font-family: "Nunito";
  --light-blue: #def1ff;
  --font-extrabold: 800;
  --font-bold: 700;
  --font-semibold: 600;
  --font-regular: 400;
  --subtle-shadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)";
  /* --ionicon-stroke-width: 64px; */




}
