

.module_content p, .module_content li{
    line-height: 2;
    color: var(--ion-color-dark);
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;


}
.module_content p{
  margin: 40px 0 0 0;
}
.module_content{
    font-size: larger;
}

.module_content ul{
    padding-left: 1.5em;
}
.module_media{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  margin: 20px 0 20px 0;
}
.module_media iframe{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
