.questions-modal{
    --height:100%;
}

.objectif-modal{
    --height:65%;
    --border-radius: 2rem 2rem 0 0;
    --margin-top: auto;

}

.objectif-modal .modal-wrapper{
    margin-top: auto;

}
.objectif-modal .modal-shadow{
    bottom:0;
}

ion-list-header{
    padding-left: 0;
}