

.swipeCards{
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  display: grid;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 386px;
  max-width: 350px;
  margin-top: 30px;
}
.helpQuality{
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 30px
}
.swipeCards.flip{
  transform: rotateY(180deg);
}
.swipeCards.flip .frontCard {
    visibility: hidden;
}
.swipeCards.flip .backCard {
    backface-visibility: visible;
}
.cardTitle{
  min-height: 6em;
  color: var(--ion-color-primary);
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button_box {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  left: 0
}
.large_button {
  font-size: 30px;
  --border-radius: 50%;
  --border-width: 8px;
  --border-color: #f3f3f3;
  --padding-start: 15px;
  --padding-end: 15px;
  margin-right: -10px;
  margin-left: -10px;
  --background-activated: #f3f3f3;
}
.space {
  width: 50px;
}
.frontCard{
  display: flex;
  grid-area: 1 / 1;
  backface-visibility: hidden;
  flex-direction: column;

}
.backCard{
  display: flex;
  grid-area: 1 / 1;
  align-items: center;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  height: 100%;
  text-align: center;
}
