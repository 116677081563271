.swiper-slide {
    display: block;
    /* width: 100px !important; */
    /* background-color: white !important; */
  }


.onboarding-modal{
    --background: white;
    --width: 100%;
    --height: 100%;
    /* display: none; */
}